import React, {useState,useEffect,useContext} from 'react'
import {Button, Col, Form, Input, Layout, Row, message, Spin, Space} from "antd";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";


import useFetch from "use-http";
import {FacebookFilled, GoogleCircleFilled, WindowsFilled} from "@ant-design/icons";
import {clickHandler} from "../util";
import {Context} from "../store";

const {Content} = Layout;


function Signup(props) {
    const navigate = useNavigate()
    const location = useLocation()
    const [searchParams, setSearchParams] = useSearchParams()
    const [state, dispatch] = useContext(Context)
    
    const {post, response, loading} = useFetch()
    const [vis,setVis] = useState(false)
    
    
    useEffect(() => {
        let iv = searchParams.get('invite_token')
        if(iv) {
            sessionStorage.setItem('invite_token', iv)
            setVis(true)
        }

        if(location.hash){
            let s =  new URLSearchParams(location.hash.substring(1))
            let idToken = s.get('id_token')
            let ivToken = sessionStorage.getItem('invite_token')
            if(idToken && ivToken){
                post(`/api/auth/signup`,{id_token:idToken,invite_token:ivToken,nonce:sessionStorage.getItem('nonce'),idp:sessionStorage.getItem('idp')})
                    .then(d => {
                        if(response.ok){
                            dispatch({type:'SET_LOGIN',payload: d})
                            navigate('/')
                        }

                        sessionStorage.removeItem('idp')
                        sessionStorage.removeItem('nonce')
                        sessionStorage.removeItem('invite_token')
                    })

            }
        }
        
      
        return () => {

        }
    }, [])
    

    return (
        <Layout><Content style={{minHeight:'100vh'}}>
            <Row justify="center" style={{margin:'50px 25px 50px 25px'}}>
                <Col>
                    <img src={'/quipu.svg'} height={100}/>
                </Col>
            </Row>

            <Row justify="center"  >
                {vis && <Spin spinning={loading} >
                    <Space direction={"vertical"}>
                        <Button icon={<GoogleCircleFilled />} size={'large'} shape="round"  onClick={async() => await clickHandler('google','signup')} style={{width:'100%'}}>Sign up with Google</Button>

                        <Button icon={<FacebookFilled />} size={'large'} shape="round" onClick={async() => await clickHandler('facebook','signup')} style={{width:'100%'}}>Sign up with Facebook</Button>

                        <Button icon={<WindowsFilled />} size={'large'} shape="round"  onClick={async() => await clickHandler('microsoft','signup')} style={{width:'100%'}}>Sign up with Microsoft</Button>
                    </Space>
                </Spin> }
            </Row>
            
            </Content></Layout>
    )
}


export default Signup
