import React, {useState,useEffect,useContext} from 'react'
import {BackTop, Layout, Menu, Dropdown, Space, Typography, Button} from "antd"
import {FileOutlined,LogoutOutlined, UserSwitchOutlined,MenuUnfoldOutlined,MenuFoldOutlined,ContactsOutlined,UserOutlined,DollarOutlined} from "@ant-design/icons"
import {Link, useLocation} from "react-router-dom"
import { Outlet,useNavigate } from "react-router"
import {Context} from "../store"
import useFetch from "use-http"

const {Content, Footer, Sider, Header} = Layout

function MLayout(props) {
    const navigate = useNavigate()
    const location = useLocation()
    const [state, dispatch] = useContext(Context)
    const {get, response} = useFetch({ suspense: true })
    const { post, response:resPost, loading:loadPost} = useFetch()

    const [collapsed, setCollapsed] = useState(true)

    
    useEffect(() => {
        
        if(!state.auth.role) {
                get(`/api/auth/me`)
                    .then(d => {
                        if(response.ok){
                            dispatch({type:'SET_LOGIN',payload: d})
                        }
                    })
        }
        
        return () => {
            
        }
    }, [])

    const menu = (
        <Menu  onClick={async (i) => {
          
            let d =  await post(`/api/auth/switch`,{tenant:Number(i.key)})
            if(resPost.ok){
                dispatch({type:'SET_LOGIN',payload: d})
                navigate('/')
            }
            
        }}>
            {state.auth?.accounts?.map(o => 
                <Menu.Item key={o.tenant.id} > {`${o.tenant.data.name} as ${o.role}`} </Menu.Item>
            )}
      
        </Menu>
    );

    return (
        <Layout style={{minHeight: '100vh'}}>
            <BackTop/>

            <Sider trigger={null} collapsible collapsed={collapsed} collapsedWidth="0">
          

                <img src={'/quipu.svg'}  width={180}/>
                <Menu theme="dark" selectedKeys={location.pathname.split('/')[2]} mode="inline" onSelect={() => {
                    setCollapsed(true)
                }}>


                    <Menu.Item key="doc" icon={<FileOutlined/>}>
                        <Link to={'/doc/list'}>Documents</Link>
                    </Menu.Item>
                       <Menu.Item key="entity" icon={<ContactsOutlined/>}>
                            <Link to={'/entity'}>Entities</Link>
                        </Menu.Item>
                    <Menu.Item key="profile" icon={<UserOutlined/>}>
                        <Link to={'/profile'}>Profile</Link>
                    </Menu.Item>
                    <Menu.Item key="payment" icon={<DollarOutlined/>}>
                        <Link to={'/payment'}>Subscription</Link>
                    </Menu.Item>


                       




                </Menu>
            </Sider>

         
            <Layout>
             

                <Header style={{padding:'0 25px'}}>
                    <div style={{float: 'left'}}>
                        {collapsed ? <MenuUnfoldOutlined style={{color: 'white'}}
                                                         onClick={() => setCollapsed(!collapsed)}/> :
                            <MenuFoldOutlined style={{color: 'white'}} onClick={() => setCollapsed(!collapsed)}/>}
                    </div>
                            <Space  style={{ float:'right'}} >
                             <Typography.Text style={{color:'white'}}>{`${state.auth?.user?.data?.name} ${state.auth?.tenant?.data?.name ? ` in ${state.auth?.tenant?.data?.name}` : ''} `}</Typography.Text>}

                                <Dropdown overlay={menu} placement="bottomCenter" >
                                    <Button shape="circle" loading={loadPost} icon={<UserSwitchOutlined />} />
                                </Dropdown>

                                <Button shape="circle" icon={<LogoutOutlined /> } loading={loadPost} onClick={async () => {
                                    await post(`/api/auth/logout`)
                                    if(resPost.ok){
                                        dispatch({type:'SET_LOGIN',payload: {}})
                                        navigate('/login')
                                    }
                                }}/>

                            </Space>
                       

                </Header>
                <Content style={{background: '#fff', padding: '1rem'}}>

                    <Outlet/>

                </Content>
                <Footer style={{textAlign: 'center', padding: 0}}> ©2022 Transactgraph Sdn Bhd</Footer>
            </Layout>
           
        </Layout>
    )
}

export default MLayout


