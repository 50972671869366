
function randomString(length) {
    let text = "";
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for(let i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
}

export const clickHandler = function (type,page){
    let redirect = encodeURIComponent(`${window.location.origin}/${page}`)
    let nonce = randomString(10)
    sessionStorage.setItem('nonce',nonce)
    sessionStorage.setItem('idp',type)
    let client
    switch (type){
        case 'google':
            client = '807015191153-nfjbf8j7bn73hrbpa7bd82lkcp38l4md.apps.googleusercontent.com'
            window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?response_type=id_token&client_id=${client}&scope=openid%20email%20profile&redirect_uri=${redirect}&nonce=${nonce}`
            break
        case 'microsoft':
            client = '6826526c-8970-4082-a257-02cade9dd940'
            window.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${client}&response_type=id_token&scope=openid%20email%20profile&redirect_uri=${redirect}&nonce=${nonce}`
            break
        case 'facebook':
            client = '331053575591915'
            window.location.href = `https://www.facebook.com/v11.0/dialog/oauth?client_id=${client}&scope=openid&response_type=id_token&response_type=id_token&scope=openid%20email&redirect_uri=${redirect}&nonce=${nonce}`
            break
    }

}