import React,{useState,useEffect,Suspense,lazy} from 'react'
import ReactDOM from 'react-dom'
import {BrowserRouter} from 'react-router-dom'
import {useNavigate, useRoutes,useLocation} from 'react-router'
import {Provider} from "use-http"
import {ErrorBoundary,useErrorHandler} from 'react-error-boundary'
import {serializeError} from "serialize-error"

import 'antd/dist/antd.variable.css';
import {message,ConfigProvider,Button,Result,notification,Modal,Skeleton} from 'antd'

import Store from "./store"
import MLayout from './containers/Layout'
import Home from './containers/Home'
import Login from "./containers/Login"
import Signup from "./containers/Signup"
const Payment = lazy(() => import("./containers/Payment"))
const Profile = lazy(() => import("./containers/Profile"))
const Entity = lazy(() => import("./containers/Entity"))
const DocList = lazy(() => import("./containers/Doc/List"))
const DocNew = lazy(() => import("./containers/Doc/create"))
const DocEdit = lazy(() => import("./containers/Doc/update"))


function sendBeacon(data,type) {
    
    if (navigator.sendBeacon) {
        navigator.sendBeacon('/api/comm/beacon', JSON.stringify({data,type}));
    } 
}

function ErrorFallback({error, resetErrorBoundary}) {
    const navigate = useNavigate()
    let res
    if(error.status === 403){
        res = <Result
            status="403"
            title="403"
            subTitle="Sorry, you are not authorized to access this page."
            extra={<Button type="primary" onClick={() => {
                resetErrorBoundary()
                navigate('/')
            }}>Back Home</Button>}
        />
    }else if(error.status === 500){
        res =  <Result
            status="500"
            title="500"
            subTitle="Sorry, something went wrong."
            extra={<Button type="primary" onClick={() => {
                resetErrorBoundary()
                navigate('/')
            }}>Back Home</Button>}
        />
    }else{
       res =  <Result
            status="error"
            title="Operation Failed"
            subTitle="There are some problems with your operation."
            extra={[
                <Button type="primary" key="report"   onClick={async () => sendBeacon(serializeError(error),'error')}>
                    Report Issue
                </Button>,
                <Button key="try" onClick={resetErrorBoundary}>Try Again</Button>,
            ]}
        >
        </Result>
    }
    return (
        <>
            {res}
        </>
  
    )
}

function MainRouter() {
    const navigate = useNavigate()
    const location = useLocation()
    const [ listening, setListening ] = useState(false)
    const handleError = useErrorHandler()
    
/*    useEffect( () => {
        if (!listening) {
            const events = new EventSource('/api/sse')

            events.onmessage = (event) => {
                const parsedData = JSON.parse(event.data)

                notification.open({
                    message: parsedData.subject,
                    description: parsedData.content
                })
            }

            setListening(true)
        }
    }, [listening])*/
    useEffect(() => {
        let handle = (e) => {
            sendBeacon(serializeError(e.error || e.reason),'error')
        }
        window.addEventListener('error', handle);
        window.addEventListener("unhandledrejection", handle);
        return () => {
            window.removeEventListener('error',handle)
            window.removeEventListener('unhandledrejection',handle)
        }
    },[])
    
    ConfigProvider.config({
        theme: {
            primaryColor: '#6f4bc2'
        }
    })
    

    const routes = [
        
        {
            path: '/', element: <MLayout/>, children: [
                {index:true,element: <Home/>},
                {path: 'payment', element: <Payment/>},
                {path: 'profile', element: <Profile/>},
                {path: 'entity', element: <Entity/>},
                {path: 'doc/list', element: <DocList/>},
                {path: 'doc/new', element: <DocNew/>},
                {path: 'doc/:id', element: <DocEdit/>},
            ]
        },
      
        {path: '/login', element: <Login/>},
        {path: '/signup', element: <Signup/>},
        {path: "*", element:   <Result
                status="404"
                title="404"
                subTitle="Sorry, the page you visited does not exist."
                extra={<Button type="primary" onClick={() => navigate('/')}>Back Home</Button>}
            />}
    ]

    let options = {
        cachePolicy: 'no-cache', interceptors: {
            response: async ({response}) => {

                if (response.status === 401) {
                    navigate('/login',{state:{returnTo:location.pathname}})
                }else if (response.status === 402) {
                    navigate('/payment')
                }else if (response.status === 403) {
                    let err = new Error('Not Authrorised');
                    err.status = 403
                    handleError(err)
                }else if (response.status === 422) {
                    message.error(response.data?.message || response.statusText)
                }else if (response.status === 404  || response.status === 400 ) {
                  handleError(response.data)
                }else if (response.status === 413 || response.status === 429) {
                    Modal.error({
                        title: response.statusText,
                        content: response.data?.message ,
                    })
                } else if(response.status >= 500){
                    let err = new Error('Server Error');
                    err.status = 500
                    handleError(err)
                }
                return response
            }
        }
    }

    return <Provider options={options}><Suspense fallback={<Skeleton active />} >{useRoutes(routes)}</Suspense></Provider>
    
}

ReactDOM.render(
    <Store>
            <BrowserRouter>
                <ConfigProvider>
                    <ErrorBoundary FallbackComponent={ErrorFallback} onError={(error,info) => console.error(error) }  >
                        <MainRouter/>
                    </ErrorBoundary>
                </ConfigProvider>
            </BrowserRouter>
        </Store>, document.querySelector('#root'))


