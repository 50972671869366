import React, {useState,useEffect} from 'react';
import { PageHeader ,Skeleton,Typography,Row,Col} from "antd";




function DList(props) {


    useEffect(() => {

        return () => {

        }
    }, [])


    return (

        <PageHeader

        >
            <Row justify={'center'}>
                <Col>
                    <img src={'/quipu.svg'} width={300}/>
                </Col>
            </Row>

            <Row justify={'center'}> 
                <Col>
                    <Typography.Title>Welcome to Quipu DMS</Typography.Title>
                </Col>
            </Row>
     
        </PageHeader>
    )
}

export default DList
