import React, {useState,useEffect,useContext} from 'react'
import {Button, Col, Form, Input, Layout, Row, message, Checkbox, Typography, Space,Spin} from "antd";
import { LockOutlined, UserOutlined,FacebookFilled,GoogleCircleFilled,WindowsFilled} from "@ant-design/icons";
import {useNavigate, useLocation, useSearchParams} from "react-router-dom";

import {Context} from "../store";
import useFetch from "use-http";
import {clickHandler} from "../util";

const {Content} = Layout;


function Login(props) {
    const navigate = useNavigate()
    const location = useLocation()
    
    const [state, dispatch] = useContext(Context)
    const {post, response, loading} = useFetch()
   
    
    useEffect(() => {
        
        if(location.state?.returnTo) sessionStorage.setItem('returnTo',location.state.returnTo)
        
       if(location.hash){
           let s =  new URLSearchParams(location.hash.substring(1))
           let idToken = s.get('id_token')
           if(idToken){
               post(`/api/auth/login`,{id_token:idToken,nonce:sessionStorage.getItem('nonce'),idp:sessionStorage.getItem('idp')})
                   .then(d => {
                       if(response.ok){
                           dispatch({type:'SET_LOGIN',payload: d})
                           let returnTo = sessionStorage.getItem('returnTo')
                           if(returnTo){
                               navigate(returnTo)
                           }else{
                               navigate('/')
                           }
                       }else {
                           if(response.status === 401) {
                               let msg = response.data?.message ? response.data?.message : 'No Account Found'
                               message.error(msg)
                           }
                       }
                       
                       sessionStorage.removeItem('idp')
                       sessionStorage.removeItem('nonce')
                       sessionStorage.removeItem('returnTo')
                   })
             
           }
       }

        return () => {

        }
    }, [])
    




    return (
        <Layout><Content style={{minHeight:'100vh'}}>
            <Row justify="center" style={{margin:'50px 25px 50px 25px'}}>
                <Col>
                    <img src={'/quipu.svg'} height={100}/>
                </Col>
            </Row>
         
            <br/>
            <Row justify="center"  >
                <Spin spinning={loading}>
            <Space direction={"vertical"}>
                <Button icon={<GoogleCircleFilled />} size={'large'} shape="round"  onClick={async() => await clickHandler('google','login')} style={{width:'100%'}}>Sign in with Google</Button>

                <Button icon={<FacebookFilled />} size={'large'} shape="round" onClick={async() => await clickHandler('facebook','login')} style={{width:'100%'}}>Sign in with Facebook</Button>

                <Button icon={<WindowsFilled />} size={'large'} shape="round"  onClick={async() => await clickHandler('microsoft','login')} style={{width:'100%'}}>Sign in with Microsoft</Button>
            </Space>
                </Spin>
            </Row>
         
          
            </Content></Layout>
    )
}


export default Login
