import React, {createContext,useReducer} from 'react'

const reducer = (state,action) => {
    switch (action.type) {
        case 'SET_LOGIN':
            return {...state,auth:action.payload}
        default:
            return state
    }
}

let initialState = {
    auth: {accounts:[]}
}

const Store = ({children}) => {
    const [state,dispatch] = useReducer(reducer,initialState)

    return (
        <Context.Provider value={[state,dispatch]}>
            {children}
        </Context.Provider>
    )
}

export const Context = createContext(initialState)

export default Store
